import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertComponent, CardComponent, FormComponent, ModalComponent } from "../../components";
import { RootState } from "../../reducers";

import { useLocation, useNavigate } from "react-router-dom";
import { getDirectBilling } from "../../actions";
import { billingErrorMessages, billingObject } from "../../interfaces";
import { ObjectUtils, USER_TOKEN } from "../../utils";
import { getAccountErrorMsg } from "../../utils/directBillingUtils";

const formFields = {
  reTransitNumber: { focus: false },
  reAccountNumber: { focus: false }
};

export const CreateDirectBilling = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let billingSessionObject = USER_TOKEN.get()?.directbilling;
  const [billingObject, setBillingObject] = useState<billingObject>({
    institutionConfigurationID: 0,
    institutionNumber: "",
    institutionName: "",
    transitNumber: "",
    reTransitNumber: "",
    accountNumber: "",
    reAccountNumber: "",
    wordTyped: "",
    isAgreed: false,
    accountNumberMinLength: 0,
    accountNumberMaxLength: 0,
    isFormValidated: false
  });

  const [billingErrorMessages, setbillingErrorMessages] = useState<billingErrorMessages>({
    transitNumberError: "",
    reTransitNumberError: "",
    accountNumberError: "",
    reAccountNumberError: "",
    institutionNumberError: "",
    isAgreedError: "",
    isTransitNumberValid: false,
    isAccountNumberValid: false,
    isValid: false
  });

  const [dirty, setDirty] = useState(false);
  const [showModal, setModal] = useState<any>({ title: "", description: "" });
  const { billingInfo } = useSelector((state: RootState) => state?.billing);
  const content = billingInfo?.contentResource?.contents || [];
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const { showAddButton, institutionsList } = billingInfo?.result ?? {};

  const toolTipIcon = ObjectUtils.extractLabelFromObject(footerContent, "ToolTipIcon");
  const lblInstitutionNumber = ObjectUtils.extractLabelFromObject(content, "lblInstitutionNumber");
  const lblInstitutionNumberOther = ObjectUtils.extractLabelFromObject(content, "lblInstitutionNumberOther");
  const lblInstitutionNumberError = ObjectUtils.extractLabelFromObject(content, "lblInstitutionNumberError");

  /** Transit Number */
  const lblTransitNumber = ObjectUtils.extractLabelFromObject(content, "lblTransitNumber");
  const lblTransitNumberError = ObjectUtils.extractLabelFromObject(content, "lblTransitNumberError");
  const lblTitleTransitNumberInfo = ObjectUtils.extractLabelFromObject(content, "lblTitleTransitNumberInfo");
  const lblTransitNumberInfoDetail = ObjectUtils.extractLabelFromObject(content, "lblTransitNumberInfoDetail");
  const lblTransitNumberNotValidError = ObjectUtils.extractLabelFromObject(content, "lblTransitNumberNotValidError");
  const lblTransitNumberReEnter = ObjectUtils.extractLabelFromObject(content, "lblTransitNumberReEnter");
  const lblTransitNumberReEnterError = ObjectUtils.extractLabelFromObject(content, "lblTransitNumberReEnterError");
  /** Transit Number */

  /** Account Number */
  const lblAccountNumber = ObjectUtils.extractLabelFromObject(content, "lblAccountNumber");
  const lblAccountNumberReEnter = ObjectUtils.extractLabelFromObject(content, "lblAccountNumberReEnter");
  const lblTitleAddBankAccount = ObjectUtils.extractLabelFromObject(content, "lblTitleAddBankAccount");
  const lblSubTitle = ObjectUtils.extractLabelFromObject(content, "lblSubTitle");
  const lblSubTitleDetail = ObjectUtils.extractLabelFromObject(content, "lblSubTitleDetail");
  const lblAccountNumberError = ObjectUtils.extractLabelFromObject(content, "lblAccountNumberError");
  const lblAccountNumberMaxMinError = ObjectUtils.extractLabelFromObject(content, "lblAccountNumberMaxMinError");
  const lblAccountNumberReEnterError = ObjectUtils.extractLabelFromObject(content, "lblAccountNumberReEnterError");
  /** Account Number */

  const getMinMaxAccountErrorMsg = getAccountErrorMsg({ lblAccountNumberError, lblAccountNumberMaxMinError });

  const btnCancel = ObjectUtils.extractLabelFromObject(content, "btnCancel");
  const btnNext = ObjectUtils.extractLabelFromObject(content, "btnNext");

  document.title = lblTitleAddBankAccount?.display;

  const regexTransitNumber = /^\d{5,}$/;
  const regexNotAllZeros = /^0*$/;

  const isOtherInstitution = billingObject?.institutionConfigurationID === 105;
  const selectedLabel = billingObject?.institutionName;

  const loadBilling = useCallback(async () => {
    dispatch(await getDirectBilling());
  }, [dispatch]);

  useEffect(() => {
    loadBilling();
  }, [loadBilling]);

  if (!ObjectUtils.isEmpty(billingSessionObject) && !ObjectUtils.isEquals(billingObject, billingSessionObject)) {
    setBillingObject(billingSessionObject);
    setbillingErrorMessages({
      ...billingErrorMessages,
      isTransitNumberValid: true,
      isAccountNumberValid: true,
      isValid: true
    });
    USER_TOKEN.removeDirectbilling();
    billingSessionObject = {};
  }

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const formattedInstitutionsLists = useMemo(() => {
    return (
      institutionsList?.map(({ institutionConfigurationID, institutionDisplayName, ...rest }) => ({
        value: institutionConfigurationID,
        label: institutionDisplayName,
        ...rest
      })) || []
    );
  }, [institutionsList]);

  // Fix: update selected institutionName when locale update.
  useEffect(() => {
    setBillingObject((billingObject) => {
      if (!billingObject.institutionName && !billingObject.institutionConfigurationID) {
        return billingObject;
      }
      const institution = formattedInstitutionsLists?.find((institute) => institute.value === billingObject.institutionConfigurationID);
      return { ...billingObject, institutionName: institution?.institutionName || "" };
    });
  }, [formattedInstitutionsLists]);

  const validationFields = (value: any) => {
    const _billErrorMsgs: Partial<billingErrorMessages> = {};

    if (value?.transitNumber !== undefined) {
      if (ObjectUtils.isTest(value?.transitNumber, regexTransitNumber)) {
        _billErrorMsgs.transitNumberError = lblTransitNumberError.display;
      } else if (!ObjectUtils.isTest(value?.transitNumber, regexNotAllZeros)) {
        _billErrorMsgs.transitNumberError = lblTransitNumberNotValidError.display;
      } else {
        _billErrorMsgs.transitNumberError = "";
        _billErrorMsgs.reTransitNumberError = "";
        _billErrorMsgs.isTransitNumberValid = true;
      }
      if (_billErrorMsgs?.isTransitNumberValid && !ObjectUtils.isEquals(value?.transitNumber, billingObject?.reTransitNumber)) {
        _billErrorMsgs.reTransitNumberError = lblTransitNumberReEnterError.display;
      }
    }

    if (billingErrorMessages.isTransitNumberValid && value?.reTransitNumber !== undefined) {
      if (billingObject?.transitNumber?.length > 0 && ObjectUtils.isEquals(billingObject?.transitNumber, value?.reTransitNumber)) {
        _billErrorMsgs.reTransitNumberError = "";
      } else {
        _billErrorMsgs.reTransitNumberError = lblTransitNumberReEnterError.display;
      }
    }

    if (value?.accountNumber !== undefined) {
      const replaced_max_length = billingObject?.accountNumberMaxLength;
      const replaced_min_length = billingObject?.accountNumberMinLength;

      const regexAccountNumber = `^\\d{${replaced_max_length},}$`;
      const regexMinMaxAccountNumber = `^\\d{${replaced_min_length},${replaced_max_length}}$`;
      const isMaxMinCheck = replaced_max_length !== undefined && replaced_min_length !== undefined && replaced_max_length !== replaced_min_length;
      const errorDisplay: any = getMinMaxAccountErrorMsg(replaced_max_length, replaced_min_length);
      if (isMaxMinCheck && ObjectUtils.isTest(value?.accountNumber, regexMinMaxAccountNumber)) {
        _billErrorMsgs.accountNumberError = errorDisplay?.display;
      } else if (!isMaxMinCheck && ObjectUtils.isTest(value?.accountNumber, regexAccountNumber)) {
        _billErrorMsgs.accountNumberError = errorDisplay?.display;
      } else if (!ObjectUtils.isTest(value?.accountNumber, regexNotAllZeros)) {
        _billErrorMsgs.isValid = false;
      } else {
        _billErrorMsgs.accountNumberError = "";
        _billErrorMsgs.reAccountNumberError = "";
        _billErrorMsgs.isAccountNumberValid = true;
      }
      if (_billErrorMsgs?.isAccountNumberValid && !ObjectUtils.isEquals(value?.accountNumber, billingObject?.reAccountNumber)) {
        _billErrorMsgs.reAccountNumberError = lblAccountNumberReEnterError.display;
      }
    }

    if (billingErrorMessages?.isAccountNumberValid && value?.reAccountNumber !== undefined) {
      if (billingObject?.accountNumber?.length > 0 && ObjectUtils.isEquals(billingObject?.accountNumber, value?.reAccountNumber)) {
        _billErrorMsgs.reAccountNumberError = "";
      } else {
        _billErrorMsgs.reAccountNumberError = lblAccountNumberReEnterError.display;
      }
    }

    return _billErrorMsgs;
  };

  const validationHandler = (value) => {
    const _billErrorMsgs = validationFields(value);
    setbillingErrorMessages({ ...billingErrorMessages, ..._billErrorMsgs });
  };

  const onInputChange = (value: any) => {
    setDirty(true);
    if (value?.reTransitNumber || value?.reAccountNumber) {
      const key = Object.keys(value)?.[0];
      formFields[key] = formFields[key] ?? {};
      formFields[key].focus = true;
    }
    validationHandler(value);
    setBillingObject({
      ...billingObject,
      ...value
    });
  };

  const validateOtherInstituteNumber = (value: string) => {
    let _billErrorMsgs: Partial<billingErrorMessages> = {};
    if (value?.length === 3) {
      _billErrorMsgs = { institutionNumberError: "" };
    } else {
      _billErrorMsgs = { institutionNumberError: lblInstitutionNumberError.display };
    }

    return _billErrorMsgs;
  };

  const otherInstitutionNumberHandler = ({ institutionNumber: value }) => {
    setBillingObject({
      ...billingObject,
      institutionNumber: value
    });
    const _billErrorMsgs = validateOtherInstituteNumber(value);
    setbillingErrorMessages({ ...billingErrorMessages, ..._billErrorMsgs });
  };

  const handleSelection = (callback: any) => {
    const { value } = callback;

    const institution = { ...callback };
    if (value !== billingObject?.institutionConfigurationID) {
      const institutionName = institution?.institutionName;
      const institutionNumber = institution?.institutionNumber;
      const replaced_min_length = institution?.accountNumberMinLength;
      const replaced_max_length = institution?.accountNumberMaxLength;

      setBillingObject({
        ...billingObject,
        accountNumber: "",
        reAccountNumber: "",
        institutionConfigurationID: value,
        institutionName: institutionName,
        institutionNumber: institutionNumber,
        accountNumberMinLength: replaced_min_length,
        accountNumberMaxLength: replaced_max_length
      });
      setbillingErrorMessages({ ...billingErrorMessages, institutionNumberError: "", accountNumberError: "", reAccountNumberError: "", isAccountNumberValid: false });
    }
  };

  const handleCancel = () => {
    USER_TOKEN.removeDirectbilling();
    navigate("/directBilling");
  };

  const handleSubmit = (event: FormEvent<EventTarget>): void => {
    event.preventDefault();
    if (billingObject) {
      USER_TOKEN.setDirectbilling(billingObject);
      navigate("/directbilling/confirm");
    }
  };

  const toggleDescription = (title: string, description: string) => {
    setModal({ title, description });
  };

  const handleClose = () => {
    setModal({ title: "", description: "" });
  };

  // On local update (USER_TOKEN.lang update way below lable update hence) listening to lblTransitNumberError update )
  useEffect(() => {
    let _billErrorMsgs = { ...billingErrorMessages };
    ["transitNumber", "accountNumber", "reTransitNumber", "reAccountNumber"].forEach((field) => {
      if (dirty) {
        // Case: If field has value then get error msg
        const errorMsgs = validationFields({ [field]: billingObject[field] });
        _billErrorMsgs = { ..._billErrorMsgs, ...errorMsgs };
      } else {
        //Case : If Field is Empty then hide error msg
        if (billingErrorMessages[`${field}Error`]) {
          _billErrorMsgs = { ..._billErrorMsgs, [`${field}Error`]: "" };
        }
      }
    });
    if (isOtherInstitution) {
      const errorMsgs = validateOtherInstituteNumber(billingObject?.institutionNumber);
      _billErrorMsgs = { ..._billErrorMsgs, ...errorMsgs };
    }
    if (!ObjectUtils.isEquals(billingErrorMessages, _billErrorMsgs)) {
      setbillingErrorMessages(_billErrorMsgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lblTransitNumberError.display]);

  const infoObject: any = {
    cardClass: "direct-billing",
    head: {
      gridtitle: lblTitleAddBankAccount?.display
    },
    body: {
      heading: showAddButton ? lblSubTitle.display : "",
      description: showAddButton ? lblSubTitleDetail.display : ""
    }
  };

  const gridRowData = [
    [
      {
        type: "select",
        isSearchSelect: true,
        name: "institutionConfigurationID",
        texttoread: lblInstitutionNumber.textToRead,
        display: lblInstitutionNumber.display,
        text: lblInstitutionNumber.display,
        selectedvalue: { label: selectedLabel, value: billingObject?.institutionConfigurationID },
        options: formattedInstitutionsLists,
        colclassname: `col-sm-12 col-md-${isOtherInstitution ? 6 : 12}`,
        mode: "edit",
        error: "",
        wrappererrorclass: "d-block",
        required: true,
        tabSelectsValue: false,
        handlechange: handleSelection
      },
      isOtherInstitution && {
        type: "text",
        regex: true,
        name: "institutionNumber",
        mode: "edit",
        display: `${lblInstitutionNumberOther?.display}<span class="ml-mandatory-sign">*</span> `,
        texttoread: lblInstitutionNumberOther?.textToRead,
        value: billingObject?.institutionNumber,
        minLength: 3,
        maxLength: 3,
        fgclass: "mb-4 row",
        handlechange: otherInstitutionNumberHandler,
        required: true,
        disableCutCopyPaste: true,
        colclassname: "col-sm-12 col-md-6",
        aria: {
          "aria-invalid":false,
          "aria-describedby": "institutionNumber-describedby"
        },
        a11yError: {
          message: billingErrorMessages?.institutionNumberError,
          props: {
            props: { id: "institutionNumber-describedby", role: "alert" }
          }
        },
        props:{
          autoComplete: "off"
        }
      }
    ],
    [
      { rowclassname: "direct-billing" },
      {
        type: "text",
        regex: true,
        name: "transitNumber",
        mode: "edit",
        display: `${lblTransitNumber?.display}<span class="ml-mandatory-sign">*</span> `,
        texttoread: lblTransitNumber?.textToRead,
        value: billingObject?.transitNumber,
        minLength: 5,
        maxLength: 5,
        tooltip: {
          type: "tooltipicon",
          ariaLabel: toolTipIcon.textToRead,
          className: "tooltip-icon icon-action ms-2",
          onSubmit: () => toggleDescription(lblTitleTransitNumberInfo.display, lblTransitNumberInfoDetail.display)
        },
        fgclass: `${!billingObject?.isFormValidated ? "mb-4" : ""}`,
        handlechange: onInputChange,
        required: true,
        disableCutCopyPaste: true,
        colclassname: "col-sm-12 col-md-6",
        aria: {
          "aria-invalid":false,
          "aria-describedby": "transitNumber-describedby"
        },
        a11yError: {
          message: billingErrorMessages?.transitNumberError,
          props: {
            props: { id: "transitNumber-describedby", role: "alert" }
          }
        },
        props:{
          autoComplete: "off"
        }
      },
      {
        type: "text",
        regex: true,
        name: "reTransitNumber",
        mode: "edit",
        display: `${lblTransitNumberReEnter?.display}<span class="ml-mandatory-sign">*</span> `,
        texttoread: lblTransitNumberReEnter?.textToRead,
        value: billingObject?.reTransitNumber,
        minLength: 5,
        maxLength: 5,
        fgclass: `${!billingObject?.isFormValidated ? "mb-4" : ""}`,
        handlechange: onInputChange,
        colclassname: "col-sm-12 col-md-6",
        aria: {
          "aria-invalid":false,
          "aria-describedby": "reTransitNumber-describedby",
        },
        a11yError: {
          message: billingErrorMessages?.reTransitNumberError,
          props: {
            props: { id: "reTransitNumber-describedby", role: formFields.reTransitNumber.focus ? "alert" : "" }
          }
        },
        required: true,
        disableCutCopyPaste: true,
        disabled: !billingErrorMessages?.isTransitNumberValid,
        props: {
          onBlur: () => {
            formFields.reTransitNumber.focus = false;
          },
          autoComplete: "off"
        }
      }
    ],
    [
      { rowclassname: "direct-billing" },
      {
        type: "text",
        regex: true,
        name: "accountNumber",
        mode: "edit",
        display: `${lblAccountNumber?.display}<span class="ml-mandatory-sign">*</span> `,
        texttoread: lblAccountNumber?.textToRead,
        value: billingObject?.accountNumber,
        minLength: billingObject?.accountNumberMinLength,
        maxLength: billingObject?.accountNumberMaxLength,
        fgclass: `${!billingObject?.isFormValidated ? "mb-4" : ""}`,
        handlechange: onInputChange,
        required: true,
        disableCutCopyPaste: true,
        colclassname: "col-sm-12 col-md-6",
        aria: {
          "aria-invalid":false,
          "aria-describedby": "accountNumber-describedby"
        },
        a11yError: {
          message: billingErrorMessages?.accountNumberError,
          props: {
            props: { id: "accountNumber-describedby", role: "alert" }
          }
        },
        props:{
            autoComplete: "off"
        }
      },
      {
        type: "text",
        regex: true,
        name: "reAccountNumber",
        mode: "edit",
        display: `${lblAccountNumberReEnter?.display}<span class="ml-mandatory-sign">*</span> `,
        texttoread: lblAccountNumberReEnter?.textToRead,
        value: billingObject?.reAccountNumber,
        minLength: billingObject?.accountNumberMinLength,
        maxLength: billingObject?.accountNumberMaxLength,
        fgclass: `${!billingObject?.isFormValidated ? "mb-4" : ""}`,
        handlechange: onInputChange,
        colclassname: "col-sm-12 col-md-6",
        required: true,
        disableCutCopyPaste: true,
        disabled: !billingErrorMessages?.isAccountNumberValid,
        aria: {
          "aria-invalid":false,
          "aria-describedby": "reAccountNumber-describedby",
        },
        a11yError: {
          message: billingErrorMessages?.reAccountNumberError,
          props: {
            props: { id: "reAccountNumber-describedby", role: formFields.reAccountNumber.focus ? "alert" : "" }
          }
        },

        props: {
          onBlur: () => {
            formFields.reAccountNumber.focus = false;
          },
            autoComplete: "off"
        }
      }
    ]
  ];

  const formInput = {
    classname: "loginform",
    validated: billingObject?.isFormValidated,
    handlesubmit: handleSubmit
  };

  let canDisableNext = true;

  if (
    billingErrorMessages.isAccountNumberValid &&
    billingErrorMessages.isTransitNumberValid &&
    !billingErrorMessages.accountNumberError &&
    !billingErrorMessages.reAccountNumberError &&
    !billingErrorMessages.transitNumberError &&
    !billingErrorMessages.reTransitNumberError &&
    !billingErrorMessages.institutionNumberError &&
    billingObject?.institutionNumber?.length > 0 &&
    billingObject?.accountNumber?.length > 0 &&
    billingObject?.reAccountNumber?.length != undefined &&
    billingObject?.reAccountNumber?.length > 0 &&
    billingObject?.transitNumber?.length > 0 &&
    billingObject?.reTransitNumber?.length != undefined &&
    billingObject?.reTransitNumber?.length > 0
  ) {
    canDisableNext = false;
  }

  const buttonInput = [
    {
      variant: "outline-secondary btn-cancel btn-sm",
      name: "cancelbtn",
      display: btnCancel?.display,
      onClick: handleCancel
    },
    {
      type: "submit",
      variant: "secondary btn-next btn-sm float-end",
      name: "btnGoNext",
      display: btnNext?.display,
      disabled: canDisableNext
      // onSubmit: handleSubmit
    }
  ];

  const descriptionModalObject = {
    showModal: showModal?.description ? true : false,
    clickHandler: handleClose,
    dialogClass: "infopopup",
    head: {
      modalTitle: showModal?.title
    },
    body: {
      confirmBoxData: {
        messagetext: showModal?.description
      }
    }
  };
  if (!showAddButton && showAddButton !== undefined) {
    navigate(-1);
  }

  return (
    <>
      {location?.state?.reload && <AlertComponent propmessageType={`success`} propmessage={"Bank account successfully added."} closeIcon={true} />}
      {billingInfo ? (
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="w-100">
                <CardComponent gridObject={infoObject}>
                  <FormComponent
                    formdata={formInput}
                    gridRowData={gridRowData}
                    btndata={buttonInput}
                    btnviewmode={1}
                    btnviewclass="d-flex justify-content-between border-top pt-3 pb-2 mt-5"
                  />
                </CardComponent>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {showModal?.description && <ModalComponent modalObject={descriptionModalObject} />}
    </>
  );
};

export default CreateDirectBilling;
