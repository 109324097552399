/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertComponent, ButtonComponent, CardComponent } from "../../components";
import { RootState } from "../../reducers";

import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getDirectBilling, saveDirectBilling } from "../../actions";
import { billingObject } from "../../interfaces";
import { ObjectUtils, USER_TOKEN } from "../../utils";

export const ConfirmDirectBilling = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { billingInfo } = useSelector((state: RootState) => state?.billing);
  const [errorMsg, setErrorMsg] = useState("");
  const [billingObject, setBillingObject] = useState<billingObject>(USER_TOKEN.get()?.directbilling);
  const [local, setLocal] = useState<string>(USER_TOKEN.get()?.lang);
  const content = billingInfo?.contentResource?.contents || [];
  const { showAddButton, lblTypeToSign, verifyTypeToSign, institutionsList } = billingInfo?.result ?? {};

  const lblInstitutionNumber = ObjectUtils.extractLabelFromObject(content, "lblInstitutionNumber");
  const lblInstitutionName = ObjectUtils.extractLabelFromObject(content, "lblInstitutionName");
  const lblPADAgreement = ObjectUtils.extractLabelFromObject(content, "lblPADAgreement");
  const PADAgreement = ObjectUtils.extractLabelFromObject(content, "PADAgreement");
  const lblPADAgreementCheckBox = ObjectUtils.extractLabelFromObject(content, "lblPADAgreementCheckBox");
  const lblSubmissionError = ObjectUtils.extractLabelFromObject(content, "lblSubmissionError");

  /** Transit Number */
  const lblTransitNumber = ObjectUtils.extractLabelFromObject(content, "lblTransitNumber");
  /** Transit Number */

  /** Account Number */
  const lblAccountNumber = ObjectUtils.extractLabelFromObject(content, "lblAccountNumber");
  const lblTitleAgreement = ObjectUtils.extractLabelFromObject(content, "lblTitleAgreement");
  const lblTitleAgreementSub = ObjectUtils.extractLabelFromObject(content, "lblTitleAgreementSub");
  /** Account Number */

  const btnCancel = ObjectUtils.extractLabelFromObject(content, "btnCancel");
  const btnNext = ObjectUtils.extractLabelFromObject(content, "btnNext");
  const btnBack = ObjectUtils.extractLabelFromObject(content, "btnBack");
  const btnSubmit = ObjectUtils.extractLabelFromObject(content, "btnSubmit");

  document.title = lblTitleAgreement?.display;

  if (local !== USER_TOKEN.get()?.lang) {
    setLocal(USER_TOKEN.get()?.lang);
    setErrorMsg("");
  }

  useEffect(() => {
    setBillingObject((billingObject) => {
      if (!billingObject.institutionName && !billingObject.institutionConfigurationID) {
        return billingObject;
      }
      const institution = institutionsList?.find((institute) => institute.institutionConfigurationID === billingObject.institutionConfigurationID);
      return { ...billingObject, institutionName: institution?.institutionName || "" };
    });
  }, [institutionsList]);

  const handleSubmit = async (event: FormEvent<EventTarget>): Promise<void> => {
    event.preventDefault();

    if (billingObject) {
      delete billingObject.reTransitNumber;
      delete billingObject.reAccountNumber;
      delete billingObject.accountNumberMinLength;
      delete billingObject.accountNumberMaxLength;
      delete billingObject.isFormValidated;

      const [err, response] = await saveDirectBilling(billingObject);
      if (err || response?.errorMessage || response?.result?.errorMessage) {
        const errorMessage = response?.errorMessage || response?.result?.errorMessage || "Something went wrong!";
        setErrorMsg(errorMessage?.trim());
        return;
      }
      USER_TOKEN.removeDirectbilling();
      navigate("/directbilling", { state: { reload: true } });
    }
  };

  const handleCancel = () => {
    USER_TOKEN.removeDirectbilling();
    navigate("/directBilling");
  };

  const ErrorComponent = useMemo(() => {
    if (errorMsg.trim() === "") {
      return "";
    }
    return <AlertComponent propmessageType="danger" heading={lblSubmissionError.display} propmessage={errorMsg} />;
  }, [errorMsg, lblSubmissionError]);

  useEffect(() => {
    const loadBilling = async () => {
      dispatch(await getDirectBilling());
    };

    !billingInfo && loadBilling();
  }, [billingInfo, dispatch]);

  useEffect(() => {
    setBillingObject((billingObject) => {
      if (!billingObject.institutionName && !billingObject.institutionConfigurationID) {
        return billingObject;
      }
      const institution = institutionsList?.find((institute) => institute.institutionConfigurationID === billingObject.institutionConfigurationID);
      return { ...billingObject, institutionName: institution?.institutionName || "" };
    });
  }, [institutionsList]);

  const infoObject: any = {
    cardClass: "direct-billing",
    head: {
      gridtitle: lblTitleAgreement?.display
    },
    cardHeaderComponent: ErrorComponent,
    body: {
      description: lblTitleAgreementSub.display ?? "",
      gridRowData: [
        [
          {
            type: "text",
            name: "institutionNumber",
            mode: "view",
            display: lblInstitutionNumber?.display,
            texttoread: lblInstitutionNumber?.textToRead,
            value: billingObject?.institutionNumber,
            minLength: 3,
            maxLength: 3,
            fgclass: "mb-2",
            lblclassname: "d-block m-0",
            colclassname: "col-sm-12 col-md-6",
            classname: "form-control-plaintext m-0"
          },
          {
            type: "text",
            name: "institutionName",
            mode: "view",
            display: lblInstitutionName?.display,
            texttoread: lblInstitutionName?.textToRead,
            value: billingObject?.institutionName,
            fgclass: "mb-2",
            lblclassname: "d-block m-0",
            colclassname: "col-sm-12 col-md-6",
            classname: "form-control-plaintext m-0"
          }
        ],
        [
          {
            type: "text",
            name: "transitNumber",
            mode: "view",
            display: lblTransitNumber?.display,
            texttoread: lblTransitNumber?.textToRead,
            value: billingObject?.transitNumber,
            fgclass: "mb-2",
            lblclassname: "d-block m-0",
            colclassname: "col-sm-12 col-md-6",
            classname: "form-control-plaintext m-0"
          }
        ],
        [
          { rowclassname: "direct-billing" },
          {
            type: "text",
            name: "accountNumber",
            mode: "view",
            display: lblAccountNumber?.display,
            texttoread: lblAccountNumber?.textToRead,
            value: billingObject?.accountNumber,
            fgclass: "mb-2",
            lblclassname: "d-block m-0",
            colclassname: "col-sm-12 col-md-6",
            classname: "form-control-plaintext m-0"
          }
        ]
      ]
    }
  };
  const canProceedToNext = billingObject?.isAgreed && billingObject?.wordTyped === verifyTypeToSign ? false : true;

  const cardFooterBtns = {
    handleCancel: [
      {
        variant: "outline-secondary btn-cancel btn-sm",
        name: "cancelbtn",
        display: btnCancel?.display,
        onSubmit: handleCancel
      }
    ],
    handleBack: [
      {
        variant: "outline-secondary btn-back btn-sm mx-4",
        name: "btnGoBack",
        display: btnBack?.display,
        onSubmit: () => navigate(-1)
      }
    ],
    handleSave: [
      {
        variant: "secondary btn-next btn-sm float-end",
        name: "btnGoNext",
        display: btnSubmit?.display,
        disabled: canProceedToNext,
        onSubmit: handleSubmit
      }
    ]
  };

  const handleChange = () => {
    setBillingObject({
      ...billingObject,
      isAgreed: !billingObject?.isAgreed
    });
  };

  const onInputChange = (event: any) => {
    setBillingObject({
      ...billingObject,
      // isAgreed: eSignatureConfirmation?.verifyTypeToSign === event.target.value ? true : false,
      wordTyped: event?.target?.value
    });
  };

  //

  if (!showAddButton && ObjectUtils.isEmpty(billingObject)) {
    navigate(-1);
  }

  return (
    <>
      {billingInfo ? (
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="w-100">
                <CardComponent gridObject={infoObject}>
                  {lblPADAgreement && PADAgreement && (
                    <div className="esignable-container">
                      <Form.Label htmlFor="padAgreement" className="mt-3" dangerouslySetInnerHTML={{ __html: lblPADAgreement?.display + " " }} />
                      <div className="mb-3 pb-3 d-flex flex-wrap">
                        <div
                          className="scroll-container"
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                          tabIndex={0}
                        >
                          <div
                            id="padAgreement"
                            className="mb-3 form-check-label warning"
                            dangerouslySetInnerHTML={{
                              __html: PADAgreement.display
                            }}
                          />
                        </div>
                      </div>
                      <div className="clearfix form-check">
                        <input type="checkbox" key="confirmSelection" className="" id="confirmSelection" checked={billingObject?.isAgreed} onChange={handleChange} />
                        <label className="mb-3 pb-3 form-check-label warning" htmlFor="confirmSelection">
                          {lblPADAgreementCheckBox?.display}
                        </label>
                      </div>
                      {lblTypeToSign && (
                        <>
                          <Form.Label
                            className="mb-3 form-check-label warning"
                            htmlFor="enableTypeToSignText"
                            dangerouslySetInnerHTML={{
                              __html: lblTypeToSign
                            }}
                          />
                          <Form.Control
                            type="text"
                            aria-label={verifyTypeToSign}
                            className="form-control login-input-field"
                            id="verificationText"
                            aria-labelledby="verificationText"
                            aria-required="true"
                            required
                            autoComplete="off"
                            value={billingObject?.wordTyped}
                            onChange={onInputChange}
                          />
                        </>
                      )}
                      <div className="d-flex justify-content-between border-top pt-3 pb-2 mt-5">
                        <ButtonComponent btndata={cardFooterBtns.handleCancel} />
                        <div className="justify-content-end">
                          <ButtonComponent btndata={cardFooterBtns.handleBack} />
                          <ButtonComponent btndata={cardFooterBtns.handleSave} />
                        </div>
                      </div>
                    </div>
                  )}
                </CardComponent>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ConfirmDirectBilling;
